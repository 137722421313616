<template>
  <el-row :gutter="15" type="flex" class="flex-wrap">
    <el-col :span="24" :md="12">
      <el-form-item label="Plan Name (ชื่อแผน)" prop="planName">
        <el-input
          placeholder="Plan Name"
          v-model="form.planName"
          clearable
          @blur="form.planName = form.planName.trim()"
        >
        </el-input>
      </el-form-item>
    </el-col>
    <el-col :span="24" :md="12">
      <el-form-item
        label="Date (วันที่)"
        prop="date"
        :class="form.date == '' && errorDate == true ? `is-error` : ''"
      >
        <el-input
          placeholder="Date"
          v-model="form.date"
          clearable
          @blur="form.date = form.date.trim()"
        >
        </el-input>
        <div
          class="el-form-item__error"
          v-if="form.date == '' && errorDate == true"
        >
          กรุณากรอกวันที่
        </div>
      </el-form-item>
    </el-col>
    <el-col :span="24" :md="12">
      <el-form-item label="Company name (ชื่อบริษัท)" prop="companyName">
        <el-select
          id="select-companyName"
          v-model="form.companyName"
          filterable
          clearable
          @clear="autoChageClear"
          @change="filterTypeCompany"
          placeholder="Company name"
          :loading="loadingAuto"
          :disabled="detailPlan >= 1 ? true : false"
        >
          <el-option
            class="infinite-list-item"
            v-for="item in optionsRefMatching"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>

    <el-col :span="24" :md="12">
      <el-row :gutter="20" type="flex" class="flex-wrap">
        <el-col :span="24" :md="12">
          <el-form-item label="Type (ประเภท)" prop="type">
            <el-select
              clearable
              v-model="form.type"
              placeholder=""
              @change="selectOptionType"
              @clear="selectOptionTypeClear"
              :disabled="optionTwoType == false ? true : false"
            >
              <el-option
                v-for="item in typeMaster"
                :key="item.typeNum"
                :label="`ตรวจ ` + item.typeName"
                :value="item.typeNum"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="Company (บริษัท)" prop="company">
            <el-select
              clearable
              v-model="form.company"
              placeholder=""
              :disabled="form.type == 2 ? false : true"
              @change="selectOptionCompany"
            >
              <template slot="empty">
                <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
              </template>
              <el-option
                v-for="item in optionsCompany"
                :key="item.companyNum"
                :label="item.companyName"
                :value="item.companyNum"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-col>

    <el-col :span="24" :md="12">
      <el-form-item label="Standard (มาตรฐาน)" prop="standard">
        <el-row :gutter="15" type="flex" class="flex-wrap">
          <el-col :span="24" :md="form.standard == 99 ? 12 : 24">
            <el-select
              clearable
              v-model="form.standard"
              placeholder=""
              :disabled="optionsStandard.length == 0 ? true : false"
              @change="selectStandard"
            >
              <template slot="empty">
                <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
              </template>
              <el-option
                v-for="item in optionsStandard"
                :key="item.standardNum"
                :label="item.standardName"
                :value="item.standardNum"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="24" :md="12" v-if="form.standard == 99">
            <el-form-item class="mg-b-less">
              <el-input v-model="form.other" disabled> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
    </el-col>
    <el-col :span="24" :md="12">
      <el-form-item prop="auditorId" label="Choose auditor (เลือกผู้ตรวจ)">
        <el-select
          filterable
          clearable
          v-model="form.auditorId"
          placeholder="Select Choose auditor "
          @change="auditorDetail"
          :disabled="
            notFoundAuditor == true || optionsAuditor.length > 0 ? false : true
          "
        >
          <el-option
            v-for="(item, index) in optionsAuditor"
            :key="index"
            :label="item.name"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="24" class="mg-t-4">
      <label for="" class="font-16 color-main">Create Assessment Plan</label>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import { HTTP } from "@/service/axios";

export default {
  props: {
    form: {
      type: Object,
      default: {},
    },
    detailPlan: {
      type: Number,
      default: 0,
    },
    errorDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      typeMaster: (state) => state.typeMaster,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
    }),
  },

  mounted() {
    this.getRefMatching();
  },
  data() {
    return {
      loadingAuto: false,
      optionTwoType: false,
      notFoundAuditor: false,
      refMatching: [],
      refInfo: [],
      refMatchingNow: null,
      optionsRefMatching: [],
      optionsStandard: [],
      optionsCompany: [],
      optionsAuditor: [],
      arrFood: [],
      arrIso: [],
      arrIsActive: [],
    };
  },
  methods: {
    autoChageClear() {
      this.form.type = "";
      this.form.company = "";
      this.form.standard = "";
      this.form.auditorId = "";
      this.optionsStandard = [];
      this.optionsCompany = [];
      this.optionsAuditor = [];
      this.optionTwoType = false;
    },
    selectOptionTypeClear() {
      this.form.company = "";
      this.form.standard = "";
      this.optionsCompany = [];
      this.optionsStandard = [];
    },
    filterTypeCompany() {
      this.selectOptionTypeClear();

      if (this.form.companyName) {
        // this.autoChageClear();
        let findMatching = this.refMatching.filter(
          (row) => row.certificationId == this.form.companyName
        );
        if (!!findMatching) {
          this.refMatchingNow = findMatching;
          //   this.refInfo = findMatching.infoAll;
          this.arrFood = [];
          this.arrIso = [];
          const groupedByType = findMatching.reduce((acc, obj) => {
            const type = obj.companyType.type == 1 ? "food" : "iso";
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(obj);
            return acc;
          }, {});
          if (groupedByType["food"] != undefined) {
            this.arrFood = groupedByType["food"];
            this.form.type = 1;
          }

          if (groupedByType["iso"] != undefined) {
            this.arrIso = groupedByType["iso"];
            this.form.type = 2;
          }

          if (this.arrFood.length > 0 && this.arrIso.length > 0) {
            this.optionTwoType = true;
            this.form.type = "";
          } else {
            this.optionTwoType = false;
          }
          if (findMatching.length > 1) {
          } else {
            this.form.type = findMatching[0].companyType.type;
            this.form.standard = findMatching[0].companyType.standard;
            if (findMatching[0].companyType.type == 1) {
              this.form.company = "";
            } else {
              this.form.company = findMatching[0].companyType.company;
            }
          }

          this.selectOptionType();
        }
      }
    },
    selectOptionType() {
      this.selectOptionTypeClear();

      const findByType = this.typeMaster.find(
        (f) => f.typeNum == this.form.type
      );
      if (this.form.type == 1) {
        this.arrFood.forEach((food) => {
          let findStd = findByType.standard.find(
            (row) => row.standardNum == food.companyType.standard
          );
          if (findStd != undefined) {
            let checkIndexOf = this.optionsStandard.indexOf(findStd);
            if (checkIndexOf < 0) {
              this.optionsStandard.push(findStd);
            }
          }
        });
      } else if (this.form.type == 2) {
        this.arrIso.forEach((iso) => {
          let findCom = findByType.company.find(
            (row) => row.companyNum == iso.companyType.company
          );
          if (findCom != undefined) {
            let checkIndexOf = this.optionsCompany.indexOf(findCom);
            if (checkIndexOf < 0) {
              this.optionsCompany.push(findCom);
            }
          }
        });
      }
      if (this.optionsCompany.length == 1) {
        this.form.company = this.optionsCompany[0].companyNum;
        this.selectOptionCompany();
      }
      if (this.optionsStandard.length == 1) {
        this.form.standard = this.optionsStandard[0].standardNum;
      }
      this.selectStandard();
    },
    selectStandard() {
      this.form.auditorId = "";
      if (this.form.standard) {
        this.arrIsActive = this.refMatching.filter(
          (row) =>
            row.certificationId == this.form.companyName &&
            row.companyType.standard == this.form.standard
        );
        let obj = {
          auditorId: [],
        };
        if (this.arrIsActive.length > 0) {
          this.arrIsActive.forEach((arr) => {
            if (this.form.type == 2) {
              arr.auditorId.forEach((id) => {
                const idExists = obj.auditorId.some((auId) => auId == id);
                if (!idExists) {
                  if (arr.companyType.company == this.form.company) {
                    obj.auditorId.push(id);
                  }
                }
              });
            } else {
              arr.auditorId.forEach((id) => {
                const idExists = obj.auditorId.some((auId) => auId == id);
                if (!idExists) {
                  obj.auditorId.push(id);
                }
              });
            }
          });
        }
        this.getAuditor(obj);
      }
      // if (this.form.type == 1) {
      //   this.optionsStandard = this.optionsStandardFood;
      // } else if (this.form.type == 2) {
      //   if (this.form.company == 1) {
      //     this.optionsStandard = this.optionsStandardIsoOne;
      //   } else {
      //     this.optionsStandard = this.optionsStandardIsoTwo;
      //   }
      // }
      // let findCatAndAudit = this.refMatchingNow.companyType.find(
      //   (row) => row.standard == this.form.standard
      // );
    },
    selectOptionCompany() {
      this.optionsStandard = [];
      this.optionsAuditor = [];

      if (this.form.company) {
        const findByCompany = this.optionsCompany.find(
          (f) => f.companyNum == this.form.company
        );
        this.refMatchingNow.forEach((match) => {
          if (this.form.company == 1) {
            let findStd = this.optionsStandardIsoOne.find(
              (row) => row.standardNum == match.companyType.standard
            );
            if (findStd != undefined) {
              let checkIndexOf = this.optionsStandard.indexOf(findStd);
              if (checkIndexOf < 0) {
                this.optionsStandard.push(findStd);
              }
            }
          } else if (this.form.company == 2) {
            let findStd = this.optionsStandardIsoTwo.find(
              (row) => row.standardNum == match.companyType.standard
            );
            if (findStd != undefined) {
              let checkIndexOf = this.optionsStandard.indexOf(findStd);
              if (checkIndexOf < 0) {
                this.optionsStandard.push(findStd);
              }
            }
          }

          if (match.companyType.standard == 99) {
            this.form.other = match.companyType.other;
          }
        });

        if (this.optionsStandard.length == 1) {
          this.form.standard = this.optionsStandard[0].standardNum;
        }
        this.selectStandard();
      } else {
        this.form.company = "";
        this.form.standard = "";
      }
    },

    getAuditor(obj) {
      this.optionsAuditor = [];
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      if (this.form.type != "" && this.form.standard != "") {
        HTTP.post(`matching/assessment/auditor`, obj)
          .then((res) => {
            if (res.data.success) {
              if (res.data.obj.length > 0) {
                this.optionsAuditor = res.data.obj.map((row) => {
                  return {
                    name: row.name + " " + row.surname,
                    email: row.email,
                    phone: row.phone,
                    _id: row._id,
                  };
                });
              }
            }
          })
          .catch((e) => {
            if (typeof e.response != "undefined") {
              if (e.response.data.status == 401) {
                this.checkAuth();
              }
            }
          });
      }
    },
    auditorDetail() {
      if (this.form.auditorId) {
        let find = this.optionsAuditor.find(
          (row) => row._id == this.form.auditorId
        );
        if (find != undefined && find != null) {
          let obj = [];
          this.arrIsActive.forEach((arrActive, indexA) => {
            const idExists = arrActive.auditorId.includes(this.form.auditorId);
            if (idExists) {
              const mapStandard =
                this.form.type == 1
                  ? this.mapDataOption(
                      this.form.standard,
                      this.optionsStandardFood,
                      "standard"
                    )
                  : this.mapDataOption(
                      this.form.standard,
                      this.form.company == 1
                        ? this.optionsStandardIsoOne
                        : this.optionsStandardIsoTwo,
                      "standard"
                    );

              const mapCompany =
                this.form.type == 2
                  ? this.mapDataOption(
                      this.form.company,
                      this.optionsCompanyIso,
                      "company"
                    )
                  : "";
              obj.push({
                matchingId: arrActive._id,
                name: find.name,

                auditorId: find._id,
                companyName: arrActive.companyName,
                certificationId: this.form.companyName,
                planName: this.form.planName,
                companyTypeId: arrActive.companyType._id,
                other: this.form.other,
                type:
                  arrActive.companyType.type == "1"
                    ? "ตรวจ Food Safety"
                    : "ตรวจ ISO",
                company: mapCompany,
                standard: mapStandard,
                date: this.formatDate(arrActive.date),
                time: arrActive.timeStart + " - " + arrActive.timeEnd,
                setDate: {
                  date: this.form.date,
                },
              });
              // this.$emit("addField", obj);
            }
          });
          this.autoChageClear();
          this.$emit("addField", obj);
          this.filterTypeCompany();
        }
      }
    },
    changeTime() {
      if (this.form.timeStart >= this.form.timeEnd) {
        this.form.timeEnd = "";
      }
    },

    getRefMatching() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"]
      HTTP.get(`matching/assessment/ref`)
        .then((res) => {
          if (res.data.success) {
            this.refMatching = res.data.obj;
            let arrIndex = [];
            this.refMatching.forEach((e) => {
              let checkIndexOf = arrIndex.indexOf(e.companyName);
              if (checkIndexOf < 0) {
                arrIndex.push(e.companyName);
              }
            });
            arrIndex.forEach((companyName) => {
              let dataNow = this.refMatching.filter(
                (c) => c.companyName == companyName
              );
              this.optionsRefMatching.push({
                label: companyName,
                value: dataNow[0].certificationId,
              });
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
  },
};
</script>
