<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="assessment-plan"
  >
    <div>
      <h2 class="mg-y-2">
        <span
          class="cr-pointer"
          @click="$router.push(`/matching/assessment-plan`)"
        >
          Assessment Plan
        </span>
        >
        <span class="color-light-blue">Create Assessment Plan</span>
      </h2>
    </div>
    <el-row :gutter="15" class="mg-b-5">
      <el-col :span="24">
        <el-card class="custom-card condition blue active" shadow="never">
          <div slot="header" class="clearfix">
            <span>Create Assessment Plan</span>
          </div>
          <div>
            <el-form
              :model="form"
              :rules="rules"
              ref="ruleForm"
              @submit.native.prevent="submitAssessment"
              :label-position="`top`"
            >
              <el-card shadow="never" class="card-inside normal">
                <AssessmentCompany
                  :form="form"
                  :errorDate="errorDate"
                  @addField="addField"
                  :detailPlan="detailPlan.length"
                />
                <div>
                  <el-tag
                    class="detail-auditor plan mg-t-3"
                    :key="indexS"
                    v-for="(item, indexS) in detailPlan"
                    :disable-transitions="false"
                    closable
                    @close="delAuditor(indexS)"
                  >
                    <p>
                      <span>
                        {{ item.companyName }}
                      </span>

                      <span>
                        {{ item.type }}
                      </span>
                      <span v-if="item.type == 2">
                        {{ item.company }}
                      </span>
                      <span>
                        {{ item.standard }}
                        {{ item.standard == "Other" ? `(${item.other})` : "" }}
                      </span>

                      <span>
                        {{ item.name }}
                      </span>
                      <span>
                        {{ item.date }}
                      </span>
                      <span>
                        {{ item.time }}
                      </span>
                    </p>
                  </el-tag>
                </div>
                <div class="js-end is-flex mg-t-4">
                  <el-button
                    type="warning"
                    round
                    :loading="loadingBtn"
                    native-type="submit"
                  >
                    <span class="font-14"> Save </span>
                  </el-button>
                </div>
              </el-card>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import AssessmentCompany from "@/components/assessment-plan/AssessmentCompany";
import VirtualList from "vue-virtual-scroll-list";

export default {
  components: {
    AssessmentCompany,
    "virtual-list": VirtualList,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,

      optionsAuditCycle: (state) => state.optionAll.auditCycle,
    }),
  },
  mounted() {},
  data() {
    var validateCom = (rule, value, callback) => {
      if (this.form.type == 2) {
        if (value == "") {
          callback(new Error("กรุณาเลือก Company"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      errorDate: false,
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          return time.getTime() < today.setDate(today.getDate() - 1);
        },
      },
      loading: false,
      loadingBtn: false,
      fetchData: [],
      page: 1,
      skip: 0,
      limit: 10,
      total: 20,
      idActive: "",
      form: {
        planName: "",
        companyName: "",
        type: "",
        company: "",
        standard: "",
        auditorId: "",
        date: "",
      },
      detailPlan: [],

      rules: {
        planName: [
          {
            required: true,
            message: "กรุณากรอกชื่อแผน",
            trigger: "blur",
          },
        ],
        companyName: [
          {
            required: true,
            message: "กรุณาเลือกชื่อบริษัท",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "กรุณาเลือก Type",
            trigger: "change",
          },
        ],
        company: [
          { validator: validateCom, trigger: "change", required: true },
        ],
        standard: [
          {
            required: true,
            message: "กรุณาเลือก Standard",
            trigger: "change",
          },
        ],
        auditorId: [
          {
            required: true,
            message: "กรุณาเลือกผู้ตรวจ",
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            message: "กรุณากรอกวันที่",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitAssessment() {
      if (this.detailPlan.length > 0 && this.form.planName != "") {
        if (this.form.date == "") {
          this.errorDate = true;
        } else {
          this.loadingBtn = true;
          this.addAssessmentPlan();
        }
      } else {
        this.$refs["ruleForm"].validate(() => {});
      }
    },
    addAssessmentPlan() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        planName: this.form.planName,
        certificationId: this.form.companyName,
        date: this.form.date,
        matching: this.detailPlan.map((plan) => {
          return {
            matchingId: plan.matchingId,
            companyTypeId: plan.companyTypeId,
            auditorId: plan.auditorId,
          };
        }),
      };
      HTTP.post(`matching/assessment/create`, obj)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              title: "สร้าง Assessment Plan สำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.$router.push(`/matching/assessment-plan`);
          } else {
            this.loadingTable = false;
            this.loadingBtn = false;
            this.$notify({
              title: "ชื่อแผนซ้ำ!",
              type: "error",
              customClass: "error",
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },
    addField(data) {
      data.forEach((group) => {
        const idExists = this.detailPlan.some(
          (obj) =>
            obj.companyTypeId === group.companyTypeId &&
            obj.matchingId === group.matchingId &&
            obj.auditorId === group.auditorId
        );
        if (!idExists) {
          this.detailPlan.push(group);
        } else {
          this.$message({
            message: "ไม่สามารถสร้าง Assessment Plan ซ้ำได้",
            type: "error",
            duration: 4000,
          });
        }
      });
      this.$refs["ruleForm"].resetFields();
      this.form.planName = data[0].planName;
      this.form.companyName = data[0].certificationId;
      this.form.date = data[0].setDate.date;
    },
    delAuditor(tag) {
      this.detailPlan.splice(tag, 1);
    },
  },
};
</script>
